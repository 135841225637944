import './App.css';
import designFrame from './assets/designFrame.png';
// import nihayaKeyLogo from './assets/NIHAYAKEYLOGO.png';
import './assets/fonts/object-medium.ttf';
import { useState, useRef,useEffect } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { app } from './utils/firebaseConfig';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CompanyInformation from './components/CompanyInformation/CompanyInformation';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

function App() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const db = getFirestore(app);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedMask, setSelectedMask] = useState('plain');
  const imageExtensions = Array(13).fill('webp');
  const totalImages = imageExtensions.length;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState({});

  const maskDetails = {
    plain: {
      name: "THE NIHAYA MASQUERADE MASK K/S23 - PLAIN",
      price: {
        currency: "€",
        amount: "155"
      },
    },
    silver: {
      name: "THE NIHAYA MASQUERADE MASK K/S23 - SILVER",
      price: {
        currency: "€",
        amount: "300"
      },
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
      return;
    }

    setIsSubmitting(true);

    try {
      const now = new Date();
      const timestamp = now.getTime();
      
      await addDoc(collection(db, "signups"), {
        email: email.toLowerCase(),
        timestamp: timestamp
      });

      setIsSuccess(true);
      setEmail('');
      
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    } catch (error) {
      console.error("Error adding document: ", error);
      if (!navigator.onLine) {
        setError("You appear to be offline. Please check your internet connection and try again.");
      } else {
        setError("Something went wrong. Please try again later.");
      }
      setTimeout(() => {
        setError('');
      }, 5000);
    }

    setIsSubmitting(false);
  };

  // Preload images
  useEffect(() => {
    imageExtensions.forEach((ext, index) => {
      const img = new Image();
      img.src = require(`./assets/productPic/mask${index + 1}.${ext}`);
      img.onload = () => {
        setImagesLoaded(prev => ({ ...prev, [index]: true }));
      };
    });
  }, [imageExtensions]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % totalImages);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + totalImages) % totalImages);
  };

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.touches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      nextImage();
    }
    if (isRightSwipe) {
      prevImage();
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/company-information" element={<CompanyInformation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/" element={
          <div className="App">
            <div className="content">
              <section className="product-section">
                <div className="product-container">
                  <div className="product-layout">
                    <div className="product-gallery">
                      <div className="carousel-container">
                        <div className="carousel-image-container"
                          onTouchStart={onTouchStart}
                          onTouchMove={onTouchMove}
                          onTouchEnd={onTouchEnd}
                        >
                          {imagesLoaded[currentImageIndex] && (
                            <img 
                              key={currentImageIndex}
                              src={require(`./assets/productPic/mask${currentImageIndex + 1}.${imageExtensions[currentImageIndex]}`)}
                              alt={`NIHAYA Mask view ${currentImageIndex + 1}`}
                              className="carousel-image"
                            />
                          )}
                          <div className="carousel-navigation">
                            <button className="nav-button" onClick={prevImage}>Previous</button>
                            <span className="image-counter">{currentImageIndex + 1} / {totalImages}</span>
                            <button className="nav-button" onClick={nextImage}>Next</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-info">
                      <div className="info-scroll">
                        <h1 className="product-title object-font">{maskDetails[selectedMask].name}</h1>
                        
                        <div className="price-section">
                          <p className="price">
                            <span className="price-currency object-font">{maskDetails[selectedMask].price.currency}</span>
                            <span className="price-amount">{maskDetails[selectedMask].price.amount}</span>
                          </p>
                          <button className="soldout-button">SOLD OUT</button>
                        </div>

                        <div className="variant-selector">
                          <label className="variant-label">SELECT VERSION</label>
                          <div className="mask-switch">
                            <button 
                              className={`switch-button ${selectedMask === 'plain' ? 'active' : ''}`}
                              onClick={() => setSelectedMask('plain')}
                            >
                              PLAIN
                            </button>
                            <button 
                              className={`switch-button ${selectedMask === 'silver' ? 'active' : ''}`}
                              onClick={() => setSelectedMask('silver')}
                            >
                              SILVER
                            </button>
                          </div>
                        </div>

                        <div className="product-description">
                          <div className="description-section">
                            <h3>Materials</h3>
                            <ul>
                              <li>PVC frame</li>
                              <li>MoTip Black Matte Spray Paint</li>
                              <li>Chicago Screws with Laser Engraved NIHAYA star</li>
                              <li>Biothane® straps with Laser Engraved NIHAYA monogram</li>
                              <li>End Caps with Swarovski® 8MM stones</li>
                              <li>Metal casing with Laser Engraved NIHAYA logo monogram</li> 
                              {selectedMask === 'silver' && (
                                <li>Sterling Silver 2.0MM cut out letters spelling NIHAYA</li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="about-section">
                <div className="about-content">
                  <h2 className="about-title">About NIHAYA</h2>
                  
                  <p>NIHAYA is a new brand that places its focus on sustainability, intricate details, lifestyle, and comfort within a dystopian framework.</p>
                  
                  <p>It paints a vibrant picture of life rising from the asphalt, the diamond in the dirt, the rose that grew out of concrete.</p>
                  
                  <div className="quote-block">
                    <p>Founder & Creative Director, Oualid Oussar, describes the brands initial releases and campaigns as pieces of fabric and material that:</p>
                    <blockquote>
                      "are portrayed in a society defined by profound social and financial inequality, indomitable authorities, and a touch of supernatural beings and/or capabilities. Nihaya translates directly from Arabic to 'End'—the end of something, and the intriguing concept that it consistently marks the beginning of something new. Endings are always boundlessly endless."
                    </blockquote>
                  </div>

                  <div className="design-credits">
                    <div className="image-section key-section">
                      <div className="nihaya-key-container">
                        {/* <span className="nihaya-text object-font">NIHAYA</span> */}
                      </div>
                    </div>
                    <div className="image-section">
                      <img src={designFrame} alt="Design frame TNMM K/S23" className="design-image" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <footer className="site-footer">
              <div className="footer-content">
                <div className="footer-left">
                  <a href="/privacy-policy">Privacy Policy</a>
                </div>

                <div className="footer-center">
                  <div className="social-links">
                    <a href="https://www.instagram.com/nihaya.eu?igsh=MTMyMDhmZmdpNmJoOQ==" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                    <a href="https://www.tiktok.com/@nihaya.eu?_t=8s6HsJd93Li&_r=1" target="_blank" rel="noopener noreferrer">
                      <FaTiktok />
                    </a>
                  </div>
                  <div className="newsletter-signup">
                    <h3>Subscribe to our newsletter</h3>
                    <p>To recieve updates, access to exclusive deals, and more</p>
                    {!isSuccess ? (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="NAME@EMAIL.COM"
                            disabled={isSubmitting}
                            required
                          />
                          <button 
                            type="submit" 
                            disabled={isSubmitting}
                          >
                            SUBSCRIBE
                          </button>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                      </form>
                    ) : (
                      <div className="success-message">
                        Your high ends now.
                      </div>
                    )}
                  </div>
                </div>

                <div className="footer-right">
                  <a href="/company-information">Company Information</a>
                </div>
              </div>
              <div className="footer-bottom">
                <p>© 
                  2024 NIHAYA | Website by <a href="https://www.joshua-digital.com" target="_blank" rel="noopener noreferrer">Joshua Digital</a></p>
              </div>
            </footer>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;